import React, { useEffect, useState } from 'react'
import cookie from 'react-cookies'
import Button from '../Button';
import { FACEBOOK_PIXEL_ID, GOOGLE_ANALYTICS_KEY, GOOGLE_TAG_MANAGER_KEY, LINKEDIN_PIXEL_ID, HOTJAR_KEY, LINKEDIN_CONVERSION_ID } from '../../../spectory-config';
import * as styles from "./cookienotice.module.scss"
import ReactGA from 'react-ga';
import LinkedInTag from 'react-linkedin-insight';
import { hotjar } from 'react-hotjar';
import TagManager from 'react-gtm-module'


import { Link } from '@reach/router';
import { withPrefix } from 'gatsby-link';

const CookieNotice = (props) => {

    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         showForm: false,
    //         cookie: cookie.load('cookienotice'),
    //         form: {
    //             analytics: {
    //                 value: false,
    //                 valid: true,
    //                 rules: {}
    //             },
    //             marketing: {
    //                 value: false,
    //                 valid: true,
    //                 rules: {}
    //             }
    //         }
    //     }

    // }

    // componentDidMount() {

    //     this.init();

    // }

    const [showForm, setShowForm] = useState(false);
    const [storedCookie, setStoredCookie] = useState(undefined);
    const [form, setForm] = useState(
        {
            analytics: {
                value: false,
                valid: true,
                rules: {}
            },
        }
    );

    useEffect(() => {

        const storedCookie = cookie.load('cookienotice')

        if (storedCookie !== undefined) {
            if (isAllowedAnalytics()) {
                if (GOOGLE_ANALYTICS_KEY !== '') {
                    ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
                    ReactGA.ga('set', 'anonymizeIp', true);
                    // ReactGA.pageview(window.location.pathname + window.location.search);
                    ReactGA.ga('send', 'pageview');
                }


                if (HOTJAR_KEY !== '') {
                    hotjar.initialize(HOTJAR_KEY)
                }

                if (GOOGLE_TAG_MANAGER_KEY !== '') {
                    TagManager.initialize({
                        gtmId: GOOGLE_TAG_MANAGER_KEY
                    })
                }
            }

            if (isAllowedMarketing()) {

                const ReactPixel = require('react-facebook-pixel');

                if (FACEBOOK_PIXEL_ID !== '') {
                    ReactPixel.init(GOOGLE_ANALYTICS_KEY);
                    ReactPixel.pageView();
                }
                if (LINKEDIN_PIXEL_ID !== '') {
                    LinkedInTag.init(LINKEDIN_PIXEL_ID, 'dc', false);
                    LinkedInTag.track(LINKEDIN_CONVERSION_ID);
                }
            }
        } else {
            setShowForm(true)
        }


    })

    const acceptAllCookies = () => {

        let formCopy = form;

        formCopy.analytics.value = true;

        setForm(prevState => ({
            ...prevState,
            analytics: {
                value: true,
                valid: true,
                rules: {}
            }
        }));

        setCookies();

    }

    const acceptSelectedCookies = () => {
        setCookies();
    }

    const setCookies = () => {

        let acceptedCookies = [];

        Object.keys(form).map(key => {

            if (form[key].value === true) {
                acceptedCookies.push(key);
            }

        });

        let expires = new Date();
        expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 100)

        cookie.save(
            'cookienotice',
            JSON.stringify(acceptedCookies),
            {
                path: '/',
                domain: window.location.hostname,
                expires,
                maxAge: 1000 * 60 * 60 * 24 * 100,
            }
        )

        setStoredCookie(cookie.load('cookienotice'))

        setShowForm(false)

    }



    const handleChange = (name, val) => {

        if (val.target !== undefined) {
            val = val.target.value;
        }

        setForm(prevState => ({
            ...prevState,
            [name]: {
                value: val,
                valid: true,
                rules: form[name].rules
            }
        }));
    };



    return (
        showForm ?
            <div className={styles.overlay} >
                <div className={styles.container}>
                    <h2>Cookies</h2>
                    <p>
                        Wir verwenden Cookies, um die Nutzung auf unserer Website zu verbessern.
                    </p>
                    <br />
                    <input
                        id="nessacaryCheckbox"
                        type="checkbox"
                        checked={true}
                        disabled
                    />
                    <label for="nessacaryCheckbox">
                        <div className="checkboxIcon"></div>
                        Notwendige
                    </label>



                    <input
                        id="analyticsCheckbox"
                        type="checkbox"
                        checked={form.analytics.value}
                        onChange={() => {
                            handleChange('analytics', !form.analytics.value)
                        }}
                    />
                    <label for="analyticsCheckbox">
                        <div className="checkboxIcon"></div>
                        Analytics
                    </label>

                    <br />
                    <br />
                    <div className="cookie_notice_buttons_container">
                        <Button action={() => acceptAllCookies()} type="primary" title="Alle Cookies akzeptieren" />
                        <br />
                        <br />
                        <Button action={() => acceptSelectedCookies()} type="secondary" title="Ausgewählte Cookies akzeptieren" />
                        <br />
                        <br />
                        <Link to={"/datenschutz"}>Datenschutz</Link>
                    </div>
                </div>

            </div>
            : null
    )

}

export const isAllowedAnalytics = () => {
    return cookie.load('cookienotice').includes('analytics');
}

export const isAllowedMarketing = () => {
    return cookie.load('cookienotice').includes('analytics');
}

export default CookieNotice