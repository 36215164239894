import React from "react"
import { Link, withPrefix } from "gatsby"
import SpectoryWhite from "../../images/spectory_logo_white.png"
import Facebook from "../../images/Icons/facebook.png"
import Linkedin from "../../images/Icons/linkedin.png"
import Instagram from "../../images/Icons/instagram.png"
import Ate from "../../images/A-TrustedExpertise.png"
import Cdc from "../../images/digital_consultant.png"
import Kmu from "../../images/KMU_Digital.png"


import * as styles from "../../scss/footer.module.scss"

const Footer = () => {
    return (
        <div className={styles.footer}>
            <div>

                <p style={{marginBottom: 10 }}>Ein Produkt von:</p>

                <a href="https://spectory.at/" target="_blank" referrerPolicy="no-referrer"><img style={{width: 200}} src={SpectoryWhite} alt="Spectory Logo" /></a>

                <br />

                <p>spectory<br />
                Waltherstraße 2<br />
                4020 Linz | Austria</p>

                <p>
                Tel: <a style={{ color: 'var(--green-color)' }} href="tel:00436766028700">+43 676 6028700</a><br />
                Mail: <a style={{ color: 'var(--green-color)' }} href="mailto:office@3garbeitsplatz.com">office@3garbeitsplatz.com</a>
                </p>

                <div className={styles.sm_div}>
                    <a href="https://at.linkedin.com/company/spectoryat" target="_blank" referrerPolicy="no-referrer"><img src={Linkedin} alt="Linkedin" /></a>
                    <a href="https://www.instagram.com/spectory_/" target="_blank" referrerPolicy="no-referrer"><img src={Instagram} alt="Instagram" /></a>
                    <a href="https://www.facebook.com/spectoryat/" target="_blank" referrerPolicy="no-referrer"><img src={Facebook} alt="Facebook" /></a>
                </div>

                <br /> <br />

                <div className={styles.images_div}>
                    <img src={Ate} alt="Austria Trusted Experties" />
                    <img src={Cdc} alt="certified digital consultant" />
                    <img src={Kmu} alt="KMU digital" />
                </div>




                <nav className={styles.nav}>
                    <div>&copy; by spectory {new Date().getFullYear()}</div>
                    <Link to={"/datenschutz"}>Datenschutz</Link>
                    <Link to={"/impressum"}>Impressum</Link>
                </nav>
            </div>

        </div>
    )
}

export default Footer
