import React, { useEffect, useState } from "react"
import { graphql, Link, StaticQuery, useStaticQuery, navigate } from "gatsby"

import * as styles from "../../scss/header.module.scss"

// import Logo from "../../images/logo_dark.svg"
// import MenuIcon from "../../images/Icons/icon_menu.svg"
// import SearchIcon from "../../images/Icons/search.svg"
// import CrossIcon from "../../images/Icons/cross.svg"
import TransitionLink from "gatsby-plugin-transition-link"
import { API_BASE } from "../../spectory-config"
import Search from "../elements/Search"

import Logo from '../../images/Logo_ATDE-09.png'
import Button from "../elements/Button"

const axios = require('axios');


const Header = (props) => {

    const [transparent, setTransparent] = useState(props.transparent !== undefined && props.transparent !== false);
    const [searchOpened, setsearchOpened] = useState(false);
    const [mobileNavOpened, setMobileNavOpened] = useState(false);
    const [mobileNavOpenedAnimated, setMobileNavOpenedAnimated] = useState(false);
    const [searchData, setSearchData] = useState(null);
    const [tldIsDeutschland, setTldIsDeutschland] = useState(false);

    const menuItems = useStaticQuery(graphql`
    query GetMenu {
        allWpMenuItem(filter: {locations: {eq: PRIMARY}}, sort: {fields: order}) {
          edges {
            node {
              url
              children {
                children {
                  id
                }
              }
              target
              label
              order
            }
          }
        }
      }
    `
    );

    let menuItemsRender = menuItems.allWpMenuItem.edges
    // menuItemsRender.sort((a,b) => {return a.order < b.order})

    useEffect(() => {

        // subscribe event
        if (props.transparent) {
            window.addEventListener("scroll", handleScroll);
        }

        axios.get(`${API_BASE}/searchContent`).then(res => {
            setSearchData(res.data);

        }).catch(err => {

        })

        return () => {
            // unsubscribe event
            if (props.transparent) {
                window.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    const handleScroll = (e) => {

        if (window.pageYOffset < 300) {
            setTransparent(true)
        } else if (window.pageYOffset >= 300) {
            setTransparent(false)
        }

    }

    const openSearch = () => {
        setsearchOpened(true);    }

    const closeSearch = () => {
        setsearchOpened(false);
    }

    const toggleSearch = () => {
        setsearchOpened(!searchOpened);
    }

    const toggleMenu = (state = null) => {

        if (state == null) {
            state = !mobileNavOpened;
        }

        if (state === true) {

            setMobileNavOpened(state)

            setTimeout(() => {
                setMobileNavOpenedAnimated(state)
            }, 100)

        } else {

            setTimeout(() => {
                setMobileNavOpened(state)
            }, 100)

            setMobileNavOpenedAnimated(state)

        }



    }

    let slug = props.slug == "/startseite" ? "/" : props.slug;

    return (
        <>
            <div className={`${styles.header} ` + (transparent ? `${styles.headerStart}` : null)}>
                <div className={styles.headerInner}>
                    <Link to={"/"} className={styles.logoLink}>
                        <img style={{ height: 70 }} src={Logo} />
                    </Link>
                    <nav className={styles.nav}>
                        <ul>
                            {
                                menuItemsRender.map((menuItem, key) => {
                                    console.log(menuItem.node.url)
                                    return <li key={key}>
                                        <Link
                                            to={menuItem.node.url}
                                            className={menuItem.node.url == slug && styles.active}
                                        >
                                            {menuItem.node.label}
                                        </Link>
                                    </li>
                                })
                            }
                        </ul>

                    </nav>
                    <Button destination="#livedemo" title="Demo vereinbaren" type="primary" additionalStyle={{position: 'absolute', right: 20}} additionalClass={styles.button}/>
                    <MenuIcon
                        onClick={() => {
                            toggleMenu()
                        }}
                        open={mobileNavOpened}
                    />
                    {
                        mobileNavOpened ?
                            <nav className={`${styles.mobileNav} ${mobileNavOpenedAnimated ? styles.open : null}`}>
                                <ul>
                                    {
                                        menuItemsRender.map((menuItem, key) => {
                                            return <li key={key}>
                                                <Link
                                                    onClick={() => toggleMenu(false)}
                                                    to={menuItem.node.url}>
                                                    {menuItem.node.label}
                                                </Link>
                                            </li>
                                        })
                                    }
                                </ul>

                            </nav>

                            : null
                    }
                </div>
            </div>

        </>
    )
}

const MenuIcon = (props) => {

    return (
        <div
            className={`${styles.mobileNavIcon} ${(props.open ? styles.open : null)}`}
            onClick={props.onClick}
        >
            <div className={styles.line}></div>
            <div className={styles.line}></div>
            <div className={styles.line}></div>
        </div>
    )

}

export default Header
